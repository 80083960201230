#dashboard{
  background-color: #D3D3D3;
  display: flex;
  flex-direction: row;
}
.body{
display: flex;
flex-direction: column;
padding-left: 4vw;
width: 80%;
font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
.body .row{
  padding-top: 7vh;
  display: flex;
  flex-direction: row;
}

.head{
  display: flex;
  flex-direction: row;
  padding-top: 4%;
}
#task{
  font-size: 4rem;

}
.table h3{
  color: #008170;
}
.ag-theme-quartz {
  
  --ag-checkbox-unchecked-color:#008170
}

.custom1 {
  background-color: #008170!important;
  box-shadow: 0 4px 10px rgba(0,0,0,0.16), 0 4px 10px rgba(0,0,0,0.23);
}
.custom2 {
  background-color: #ae2012!important;
  box-shadow: 0 4px 10px rgba(0,0,0,0.16), 0 4px 10px rgba(0,0,0,0.23);
}
.custom3{
  background-color:#ee9b00!important;
  box-shadow: 0 4px 10px rgba(0,0,0,0.16), 0 4px 10px rgba(0,0,0,0.23);
}
.custom-register{
  box-shadow: 0 4px 10px rgba(0,0,0,0.16), 0 4px 10px rgba(0,0,0,0.23);
}
.table{
  margin: 5%;
}

