
.Sidebar{
    min-height: 100vh;
    max-height: auto;
    overflow-y: auto;
    width: 17%;
    background-color: #2f4050;
    display: flex;
    flex-direction: column;
  }
.Sidebar h1{
    color: white;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    padding: 5%;
    
}
.SidebarList{
    height: auto;
    width: 100%;
    padding: 0;
}
.SidebarList .row{
    width: 100%;
    height:80px;
    list-style-type: none;
    margin: 0%;
    display: flex;
    flex-direction: row;
    color: white;
    justify-content: center;
    align-items: center;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: x-large;
}
.SidebarList .row:hover{
    cursor: pointer;
    background-color: #293846;
}
.SidebarList #active{
    background-color: #008170;
}
.row #icon{
    flex: 30%;
    display: grid;
    place-items: center;
}

.row #title{
    flex: 70%;
}
@media (max-width: 600px) {
    #title {
      display: none;
    }
    .Sidebar h1{
        font-size: 0%;
    }
    .w-50{
        font-size: small;
        width: 100%!important;
        
    }
    .custom-register{
        width: 100%!important;
        
    
    }
    .custom-register button{
        font-size: small;
        
    }
    .card{
        width: 80vw!important;
        height: auto!important;
        margin-left: 1vw!important;
    }
    .card .form-floating{
        margin:3px !important;
        
    }
    .row .card{
        width: 70vw!important;
    }
    .row .card-title{
        font-size: large;
    }
    .col-sm-8{
        padding-left: 0!important;
        margin-left: 0!important;
        
    }
    .custom-row{
        display: flex!important;
        flex-direction: column!important;
        margin: 0!important;
        padding: 0!important;
    }
    .table .ag-theme-quartz{
        width: auto!important;
        padding: 2%!important;
        }
    .table{
        margin: 0!important;
    
    }
  }
  